/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
*,
*:before,
*:after {  box-sizing: inherit; }
html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; box-sizing: border-box; position: relative; min-height: 100%; }
body { margin: 0;font-family: 'Kanit', sans-serif; font-size:23px; line-height:30px;}
ul,ol { margin: 0 0 20px; padding: 0 0 0 20px; }
ul { list-style: disc; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 1.5em; }
img { height: auto; max-width: 100%; }
a {background-color: transparent;text-decoration: none;transition: 0.5s ease all;}
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none;}
.alignleft { display: inline; float: left; margin-right: 35px; margin-bottom: 20px; margin-top: 15px; }
.alignright { display: inline; float: right; margin-left: 35px; margin-bottom: 20px; margin-top: 15px; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; margin-top: 15px; margin-bottom: 20px; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}


/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/

.clear{clear: both;}
.container { width:1495px;  }


.alignleft { display: inline; float: left; margin-right: 35px; margin-bottom: 20px; margin-top: 15px; }
.alignright { display: inline; float: right; margin-left: 35px; margin-bottom: 20px; margin-top: 15px; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; margin-top: 15px; margin-bottom: 20px; }

img{max-width: 100%;height: auto;}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.site-header {background: #fff;box-shadow: 0 3px 8px rgba(0,0,0,0.4);position: relative;z-index: 5;}
.site-header .logo{padding: 23px 0;}
.site-header .logo a{display: inline-block;}
.site-header .logo img{width: 100%;max-width: 470px;}
.site-header #primary-menu { float: right;padding:0 30px 0 0;margin: 0;}
.navigation ul li{display: inline-block;position: relative;list-style: none;}
.navigation ul li a{color: #4eabba;font-size: 23px;font-weight: 700;font-style: italic;display: inline-block;text-transform: uppercase;padding: 57px 27px 40px;}
.navigation ul li > a:hover, .navigation ul li.current-menu-ancestor > a, .navigation ul li.current_page_item > a { color: #0b3a56; }
.navigation ul li ul.sub-menu { display: none; }
.navigation ul li ul.sub-menu li:first-child { display: block; }
.navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu {display: block;position: absolute;top: 100%;left: 0px;width: 100%;min-width: 200px;background-color: #fff;padding: 5px;z-index: 2;text-align: left;}
.navigation ul li ul.sub-menu li ul.sub-menu{left: 100%;top: 0;margin-left: 0;}
.navigation ul li:last-child.open > ul.sub-menu,.navigation ul li:last-child:hover > ul.sub-menu { left: auto; right: 0; }
ul.sub-menu li { display: block;     line-height: 14px;}
ul.sub-menu li a {display: inline-block; line-height: 20px; padding: 12px 20px !important;}

/******************Slider******************/
.banner-slider{position: relative;}
.banner-slider .item {background-position: center center;background-repeat: no-repeat;background-size: cover;position: relative;}
.banner-slider .owl-item .item img {display: block;opacity: 0;width: 100%;}
.banner-slider .owl-dots{position: absolute;bottom: 23px; width:100%;max-width: 1465px;transform: translateX(-50%);left: 50%;}
.owl-dot{height: 12px; width: 13px; border-radius: 50%; background: #fff;display: inline-block;margin: 0 15px 0 5px;}
.owl-dot.active{background: #4eabba;}

/********************* Main Section********************/
.main_content_text{padding: 53px 30px 20px 0;}
.main_content p{color: #231f20;font-size: 23px;line-height: 30px;margin-bottom: 30px;font-weight: 300;}
.main_content .btn {border: 2px solid #0a3a56; font-size: 23px;}
.main_content .btn:hover {opacity: 0.5;}
.main_content ul{margin-bottom: 30px;}
.main_content ul li{color: #231f20;font-size: 23px;line-height: 30px;font-weight: 300;}
.side_menu{background: #0a3a56;height: 100%;position: relative;z-index: 1;padding: 24px 16px 73px;margin-left: 22px;box-shadow: 1px 0 18px rgba(0,0,0,0.5);}
.side_menu:after{content: '';position: absolute;height: 80px;width: 100%;top:-55px;left: 0;background: #0a3a56;transform: skewY(-4deg);-webkit-transform: skewY(-4deg);}
.side_menu:before{content: '';position: absolute;height: 40px;width: 100%;top:-75px;left: 0;background: #4eabba;transform: skewY(-4deg);-webkit-transform: skewY(-4deg);-webkit-box-shadow:0px 0 17px 1px rgba(0,0,0,0.63);-moz-box-shadow:0px 0 17px 1px rgba(0,0,0,0.63);box-shadow:0px 0 17px 1px rgba(0,0,0,0.63);}
#menu-sidebar-menu { position: relative; z-index: 20;margin: 0;padding: 0 20px;}
#menu-sidebar-menu li {display: block; list-style: none; padding: 20px 20px 25px 60px;border-bottom: 2px solid rgba(78, 171, 186, 0.45);color: #fff;font-size: 25px;font-weight: 700;font-style: italic;text-transform: uppercase;position: relative;}
#menu-sidebar-menu li:after{content: '';position: absolute;left: 0;top:50%;transform: translateY(-50%);background: url("../media/plane-icon.png")no-repeat;background-size: 100%;width: 31px;height: 34px;display: inline-block;vertical-align: middle;}
.sidebar_image_container { padding: 40px 20px 0; text-align: center; }
/**************** Footer **********************/
footer{background: #231f20;padding: 25px 0;color: #fff;z-index: 1;-webkit-box-shadow: -1px -8px 23px -15px rgba(0,0,0,0.75);-moz-box-shadow: -1px -8px 23px -15px rgba(0,0,0,0.75);box-shadow: -1px -8px 23px -15px rgba(0,0,0,0.75); }
footer:before{
	content: '';
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+26,000000+100&0+0,0.25+22,0.61+43,1+77,1+100,1+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 22%, rgba(0,0,0,0.32) 26%, rgba(0,0,0,0.61) 43%, rgba(0,0,0,1) 77%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 22%,rgba(0,0,0,0.32) 26%,rgba(0,0,0,0.61) 43%,rgba(0,0,0,1) 77%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 22%,rgba(0,0,0,0.32) 26%,rgba(0,0,0,0.61) 43%,rgba(0,0,0,1) 77%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
   display: block !important; bottom: 0; left: 0; right: 0; width: 100%; top: 0px;
}
footer .container{position: relative;z-index: 1;}
.footer_img{display: inline-block;vertical-align: middle;}
.footer_img img{max-width: 140px;width: 100%;}
.heading{font-size: 30px;font-weight: 700; font-style: italic; text-transform: uppercase;margin-bottom: 0;}
.footer_info{margin-left: 40px;display: inline-block;vertical-align: middle;}
.sitemap{margin-top: 15px;}
.footer_info.address{margin-left: 78px;}
footer .heading{color: #fff; font-size: 18px; margin: 0; padding-bottom: 10px; }
.footer_info p{font-size: 14px; line-height: 17px; }
.footer_info .phone{font-weight: 700;font-size: 18px;color: #fff;}
footer #primary-menu{ display: block;padding: 0; }
footer #primary-menu li{ display: inline-block; position: relative;padding-right:12px;}
footer #primary-menu li:after{content: '';position: absolute;right: 0;top:50%;background: #fff;width: 8px;height: 2px;transform: translateY(-50%);color: #fff;margin-top: 2px;}
footer #primary-menu li:first-child a{padding-left: 0;}
footer #primary-menu li:last-child:after{display: none;}
footer #primary-menu li a{font-size: 14px; color: #fff;position: relative;padding: 0 5px;}
.footer_info p.copyright{font-style: italic;color: #4a4a4a;font-size: 16px;margin-top: 35px;}

/****************** Inner Section**************************/
.banner-img{background-size: cover;background-repeat: no-repeat; background-position: center center;min-height: 85px;}
.banner-img img{width: 100%;opacity: 0;}
.inner-page .main_content_text{padding: 48px 30px 20px 0;}
.inner-content h2{color: #0a3a56;font-size: 33px;font-weight: 700;font-style: italic;text-transform: uppercase;margin-bottom: 22px;}
.inner-content a{color: #0a3a56;font-size: 23px;font-weight: 700;font-style: italic;}
.inner-page .inner-content, .inner-page.main_content p{text-align: left;padding: 0;}
.inner-page.main_content p img{max-width: 352px; width: 100%;}
.featured-image { float: right; margin: 20px; }
.featured-image img { width: 100%; border-radius: 50%;}
