@media (max-width:1850px){
	.side_menu:after{top: -45px}
	.side_menu:before{top: -65px}
}
@media (max-width:1700px){
	.side_menu:after{top: -25px}
	.side_menu:before{top: -40px}
}
@media (max-width:1550px) {
	.container { width: 1200px; }
	.banner-slider .owl-dots{max-width: 1200px;}
	.navigation ul li a{padding: 38px 12px 40px;}
	#menu-sidebar-menu li {padding: 30px 0px 30px 48px; font-size: 20px;}
	.footer_info.address {margin-left: 24px;}
	.round_img{width: auto;}
}

@media (max-width:1280px) {
	body {font-size: 20px;line-height: 25px;}
	.container { width: 960px; }
	.banner-slider .owl-dots{max-width: 960px;}
	.navigation ul li a{font-size: 20px;}
	.navigation ul li a {padding: 38px 5px;}
	.main_content p{font-size: 20px;line-height: 25px;}
	.main_content .btn {font-size: 20px;}
	.main_content ul li{font-size: 20px;line-height: 25px;}
	.main_info .col-sm-5.main-box-eqheight {min-height: 430px;}
	.side_menu{margin-left: 0;margin-top: 0;}
	.side_menu:after{top:-25px;}
	.side_menu:before{top:-45px;}
	#menu-sidebar-menu li {padding: 25px 0px 25px 45px;font-size: 18px;}
	#menu-sidebar-menu li:after{background-size: 80%;height: 28px;}
	.featured-image { width: 45%; }
    footer #primary-menu li:after { width: 5px; }
    footer #primary-menu li a { padding: 0; }
}

@media (max-width:991px) {
	.container { width: 740px; }
	.banner-slider .owl-dots{max-width: 740px;}
	header #primary-menu{padding-right: 0;}
	.navigation ul li a{font-size: 16px;padding: 34px 4px;}
	.main_content_text {padding: 22px 0px;}
	.side_menu::before { top: -39px;}
	#menu-sidebar-menu li {padding: 20px 0px 20px 40px; font-size: 16px;}
	#menu-sidebar-menu li:after {background-size: 65%;height: 25px;}
	.footer_img img {max-width: 104px;}
	.footer_info.address { margin-left: 18px; margin-top: 18px;}
	.sitemap {margin-top: 18px;}
	.featured-image { float: none; margin: 60px auto; width: 100%;}
}

@media (max-width:767px) {
	body {font-size: 15px;}
	.container { width: 100%; }
	header .logo {padding: 14px 0;}
	.res_box_shadow{display: block;width: 100%;position: relative;-webkit-box-shadow: 0px 10px 31px -14px rgba(0,0,0,0.55);-moz-box-shadow: 0px 10px 31px -14px rgba(0,0,0,0.55);box-shadow: 0px 10px 31px -14px rgba(0,0,0,0.55);}
	.mobile-icon { background: #4eabba;padding: 7px 0; }
 	.mobile-icon a { width: 105px;height: 20px;background-image: url(../media/moblie-icon.png);font-size: 20px;line-height: 30px;color: #FFF;display: inline-block;font-style: italic;font-weight: 700;text-transform: uppercase;-webkit-transition: all 0.3s ease-in-out 0s;transition: all 0.3s ease-in-out 0s;background-repeat: no-repeat;background-position: 0 5px;padding-left: 50px;height: 30px;}
    .mobile-icon a img { display: none; }
	.mobile-icon img {opacity: 0;}
	.open-menu .mobile-icon { text-align: right; }
	.open-menu .mobile-icon a { background-image: url(../media/moblie-icon-close.png); background-position: 75% 5px;font-size: 0;}
	.open-menu .header-right { right: 0; }
	.open-menu .fa-bars:before { content: "\f00d"; }
	.banner-slider .item{min-height: 152px;}
	.side_menu{margin-top: 100px;padding: 42px 16px 73px;}
	.side_menu::after{height: 75px;}
	.header-right { position: fixed; right: -250px; top: 0; height: 100%; width: 250px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s;
    z-index: 19; background: #0a3a56; text-align: left; overflow: auto; padding: 30px 0; margin: 0; overflow-x: hidden; -webkit-box-shadow: 0px 2px 5px rgba(0,0,0,0.5); box-shadow: 0px 2px 5px rgba(0,0,0,0.5); }
    .navigation ul li{display: block;}
    header #primary-menu{float: none;}
    .navigation ul li.menu-item-has-children > a:after {content: "\f107";display: inline-block;margin-left: 5px;font-family: FontAwesome;font-size: 18px;font-weight: bold;position: relative;top: 2px;}
    .navigation ul li a {padding: 14px 10px; color: #fff;display: block;border-bottom: 1px solid rgba(255,255,255,0.4);}
    .navigation ul li > a:hover, .navigation ul li.current-menu-ancestor > a, .navigation ul li.current_page_item > a{color:#4eabba;}
    .navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu {min-width: 100%;left: 0;margin-top: 0;position: relative;background: transparent;padding: 0;color:#4eabba;}
	ul.sub-menu li a { padding-left:25px; }
	.navigation ul li ul.sub-menu li ul.sub-menu{left: 0;}
	.site {  -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
	.open-menu .site { left: -250px; }
	.main_info .col-sm-5{padding: 0;}
	.banner-slider .owl-dots {max-width: 740px;left: 50%; transform: translateX(-50%);width: auto;bottom: 6px;}
	.owl-dot {height: 9px;width: 9px;margin: 0 8px 0 5px;}
	.main_content{text-align: center;padding-top: 10px;}
	.main_content p{font-size: 15px;text-align: center;line-height: 20px;padding: 0 16px;margin-bottom: 20px;}
	.main_content ul{padding: 0 16px;margin-bottom: 20px;}
	.main_content ul li{font-size: 15px;line-height: 20px;}
	.side_menu{padding: 21px 14px 50px; margin-top: 75px;}
	.side_menu::after {height: 50px;top:-22px;}
	.side_menu:before{top: -33px;}
	.main_info .col-sm-5.main-box-eqheight {  min-height: 100%; }
	#menu-sidebar-menu li {font-size: 15px; padding: 20px 0px 15px 40px;line-height: 1;}
	#menu-sidebar-menu li:after {background-size: 58%;height: 22px;margin-top: 4px;}
	footer{text-align: center;position: relative;}
	.footer_img img {max-width: 118px;}
	footer #primary-menu li:last-child{padding-right: 0;}
	.footer_img, .footer_info.address{display: block;}
	.footer_img{margin-bottom: 40px;}
	.footer_info.address, .sitemap{margin-left: 0;}
	.banner-img{min-height: 41px;}
	.inner-page .main_content_text{padding: 12px 10px;}
	.inner-content h2{font-size: 25px;margin-bottom: 16px;}
	.inner-page .inner-content, .inner-page.main_content p, .inner-content a, .inner-page.main_content ul li, .inner-page.main_content .btn {font-size: 15px;}
	.round_img{margin-top: 45px;}

}
